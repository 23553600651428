/* dark: ['#303840', '#7d8187'],*/
.modal-content {
    --bs-modal-inner-border-radius: 10px;
    /* affected kosh ticker lookup - fix using otto */
    /*--modal-background-color: #fff;*/
}

.otto-link {
    color: whitesmoke;
}
.otto-link:hover {
    cursor: pointer;
    color: rgba(26, 26, 26, 1);
    background-color: rgb(233 229 251);
}
.otto-link.active {
    font-weight: bold;
    color: #0a7f7a;
}
.nav-link:hover {
    text-decoration: none !important;
}
.otto-nav-link {
    /*background-color: #202931;*/
    /*color: #d0c5f8;*/
    color: #6f4bf2;
    /*border-radius: 20px;*/
    margin: 0 10px !important;
    padding: 10px 20px !important;
    cursor: pointer;
    font-weight: 800;

    @media (min-width: 992px) {
        font-size: 14px;
    }
    @media (max-width: 992px) {
        font-size: 12px;
    }
}
.otto-nav-link:hover {
    color: #6f4bf2;
}

.otto-nav-link.active {
    color: #6f4bf2;
    text-decoration-color: #6f4bf2;
    text-decoration-line: underline;
    text-underline-offset: 4px;

    /*background-color: #7d8187;*/
}
.line-height-2 {
    line-height: 1.5;
}
.link-text {
    color: #9aa9b2;
    font-weight: lighter;
}
