.p-datatable .p-datatable-header {
    background: none !important;
    border: none !important;
    padding: 0 !important;
}

.p-toolbar {
    background: none !important;
    border: none !important;
    padding: 0 !important;
}

.p-title {
    font-size: 18px;
    font-weight: 600;
}

.highlighted-row {
    background-color: #e7e7ef !important;
}

.orderBy {
    cursor: pointer;
}

.orderBy-asc {
    position: relative;
}

.orderBy-desc {
    position: relative;
}

.orderBy-asc > caret {
    margin: 10px 0;
    color: #cccccc;
}

.orderBy-desc > caret {
    margin: 10px 0 10px 5px;
    color: #cccccc;
}

.orderBy-right > caret {
    margin: 10px 10px 10px 5px;
    color: #cccccc;
}

.orderBy-asc .caret,
.navbar-fixed-bottom .orderBy-desc .caret {
    content: '';
    border-top: 0;
    border-bottom: 4px dashed;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.pagination-wrapper {
    width: 100%;
    text-align: center;
}

.pagination {
    text-align: center;
    display: inline-flex;
    font-size: 0.85em;
}

.pagination-per-page {
    display: inline-flex;
    vertical-align: middle;
    min-width: 60px;
}

.pagination-label {
    display: inline-flex;
    margin-top: 8px;
}

.pagination-buttons {
    margin-left: 5px;
    margin-top: 4px;
    margin-right: 5px;
}

.pagination > li.page-item.disabled > .page-link {
    /*background-color: var(--widget-blue-light) !important;*/
}
