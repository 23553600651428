/* Custom style */

.cron-component {
    padding: 10px 5px;
}
.cron-component-error {
}
.cron-component-disabled {
}
.cron-component-read-only {
}
.cron-component-clear-button {
}
.cron-component-field {
}
.cron-component-field > span {
    font-weight: lighter;
    font-size: 11px;
    color: #fafafa;
}
.cron-component-period {
}
.cron-component-minutes {
}
.cron-component-hours {
}
.cron-component-months {
}
.cron-component-month-days {
}
.cron-component-week-days {
}
.cron-component-select {
}

.cron-component-select-dropdown {
}
.ant-select-item {
    position: relative;
    display: block;
    min-height: 22px;
    padding: 2px 2px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    transition: background 0.3s ease;
}
.cron-component-select-dropdown-period {
}
.cron-component-select-dropdown-minutes {
}
.cron-component-select-dropdown-hours {
}
.cron-component-select-dropdown-months {
}
.cron-component-select-dropdown-month-days {
}
.cron-component-select-dropdown-week-days {
}
.cron-component-error.cron-component-field > span {
    color: #ff4d4f;
}
.cron-component .cron-component-select > div:first-child {
    /*font-size:12px!important;*/
}
.cron-component-select-dropdown.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #31359b !important;
}
.cron-component-clear-button {
    border: 1px solid #ed9c28 !important;
    background-color: inherit !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fafafa;
    background-color: #202931 !important;
}
