/*--------------------- code - panel - start*/
.code-panel {
    text-align: left;
    font-size: 1rem;
    height: 100%;
    overflow: hidden;
}

.code-panel section {
    flex: 1;
    height: 50%;
}

.code-panel section .codepanel-separator {
    display: flex;
    justify-content: space-between;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}

.code-panel section .codepanel-label {
    margin-top: 5px;
    line-height: 20px;
    font-size: 13px;
    color: #aaa;
    user-select: none;
    text-transform: uppercase;
}

.cp-btn-container {
    display: flex;
    justify-content: space-evenly;
}

.cp-btn-container .gjs-btn-prim {
    margin: 2.5px;
}

.gutter {
    cursor: ns-resize;
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
}

.gutter:after {
    content: '';
    display: block;
    height: 8px;
    width: 100%;
    position: absolute;
    top: -3px;
    z-index: 150;
}

.code-panel .CodeMirror {
    height: 100%;
}

.cp-delete-css {
    margin-left: 70%;
}

.gjs-pn-views {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 0;
}

.gjs-pn-views-container {
    box-shadow: initial;
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    top: 40px;
    padding-top: 0;
    height: calc(100% - 40px);
}

.gjs-pn-views-container,
.gjs-cv-canvas {
    transition: width 0.3s ease-in-out;
}

/*--------------------- code - panel - end*/

/*--------------------- grapesjs-user-blocks - start*/
/*--------------------- grapesjs-user-blocks - end*/

.form-row {
    display: flex;
    align-items: center;
    gap: 30px;
}

.form-column {
    flex-basis: 40%;
    background-color: #363636;
    border: solid 1px #2c2c2c;
    margin: 2px;
    padding: 6px;
    color: white;
}

.modal-content {
    margin-top: 28px;
}

.container {
    margin-top: 16px;
    margin-bottom: 32px;
}

#screenShotCanvas {
    max-width: 750px;
    max-height: 300px;
    overflow: auto;
}

.button {
    padding: 5px 10px 5px 10px;
    background-color: #363636;
    border: solid 1px #2c2c2c;
    color: white;
    border-radius: 5px;
}

/*--------------------- grapesjs-user-blocks - end*/
