@import './animations.css';

:root {
    --mini-navbar-width: 3rem;
    --xs-text: 0.7rem;
}
#calendar1 {
    border: 0 !important;
    /*color: transparent !important;*/
    background-color: transparent !important;
}
.custom_theme_maker {
    padding: 10px;
    position: absolute;
    z-index: 10;
    bottom: 0;
    min-height: 300px;
    width: 100vw;
    justify-content: center;
    background-color: rgb(4, 9, 23);
}

.primary_text {
    color: var(--primary_text) !important;
}

.secondary_text {
    color: var(--secondary_text) !important;
}

.accent_text {
    color: var(--accent-color) !important;
}

.accent_bg_animation {
    background: var(--accent-color) !important;
    background: linear-gradient(250deg, rgba(32, 32, 33, 0) 10%, var(--accent-color) 100%) !important;
    padding-top: 2px;
}

.primary_bg {
    background-color: var(--primary_bg) !important;
}

.secondary_bg {
    background-color: var(--secondary_bg) !important;
}

.side_nav_background {
    background: var(--secondary_bg) !important;
    background: linear-gradient(250deg, var(--primary_bg) 10%, var(--secondary_bg) 100%) !important;
}

option .secondary_background,
.secondary_background,
.blinkers_level_2 {
    background: var(--secondary_bg) !important;
    background: linear-gradient(-250deg, var(--primary_bg) 10%, var(--secondary_bg) 100%) !important;
}

.chart_background {
    background: var(--chart_bg) !important;
    background: linear-gradient(-250deg, var(--chart_bg) 10%, var(--chart_bg_2) 100%) !important;
}

.avatar {
    max-width: 100px;
    max-height: 100px;
}

.backdrop_filter_light {
    backdrop-filter: blur(15px);
    background-color: var(--secondary_bg_40) !important;
    color: var(--dark_text) !important;
    -moz-box-shadow: inset 0 0 10px var(--secondary_bg) !important;
    -webkit-box-shadow: inset 0 0 10px var(--secondary_bg) !important;
    box-shadow: inset 0 0 10px var(--secondary_bg) !important;
}

.backdrop_filter_dark {
    backdrop-filter: blur(15px);
    background-color: var(--dark_bg_40) !important;
    color: var(--secondary_bg) !important;
    -moz-box-shadow: inset 0 0 10px var(--primary_bg) !important;
    -webkit-box-shadow: inset 0 0 10px var(--primary_bg) !important;
    box-shadow: inset 0 0 10px var(--primary_bg) !important;
}

.list-group-item {
    border-left: 5px solid transparent !important;
    cursor: pointer;
    color: var(--secondary_text) !important;
}

.list-group-item:hover,
.main_list_item:hover,
.main_list_item.active,
.list-group-item.active {
    background: var(--primary_bg) !important;
    color: var(--primary_text) !important;
}

.main_list_item:hover,
.main_list_item.active {
    border-left: 5px solid var(--accent-color) !important;
}

/*
    WIDGETS
*/
.inside_border_right_bottom {
    border-bottom-right-radius: var(--widget-border-radius) !important;
}

.inside_border_left_bottom {
    border-bottom-left-radius: var(--widget-border-radius) !important;
}

.inside_border_right_top {
    border-top-right-radius: var(--widget-border-radius) !important;
}

.inside_border_left_top {
    border-top-left-radius: var(--widget-border-radius) !important;
}

.inside_border_all {
    border-radius: var(--widget-border-radius) !important;
}

.inside_border_all,
.inside_border_left_bottom,
.inside_border_left_top,
.inside_border_right_top,
.inside_border_right_bottom {
    padding: 10px;
}

.widget_background {
    background-color: var(--widget_bg);
}

.widget_heading {
    color: var(--secondary_text);
}

.widget_text {
    color: var(--primary_text);
}

.widget_footer {
    color: var(--secondary_text);
}

/*mini sideNav*/
.col-md-1.layout {
    width: 4.16666665% !important;
}

.col-md-11.layout {
    width: 95.83333335% !important;
}

ul.mini {
    list-style-type: none;
    margin: 0 !important;
    padding: 0 !important;
}

.mini_navbar_wrapper {
    /*width: var(--mini-navbar-width);*/
    background-color: var(--widget_bg) !important;
}

.list-item.mini {
    background: var(--widget_bg) !important;
    color: var(--secondary_text) !important;
    text-align: center;
    height: 2.5em;
    /*width: var(--mini-navbar-width);*/
    vertical-align: middle;
    line-height: 2.5em;
    border-bottom: var(--accent-color) !important;
    position: relative;
    display: block;
    text-decoration: none;
    transition: all 0.25s linear;
}

.list-item.mini:hover {
    background: var(--secondary_bg_60) !important;
    color: var(--primary_text) !important;
    transform: translate(0.2em, -0.2em);
    transition: all 0.25s linear;
}

.list-item.mini:before,
.list-item.mini:after {
    content: '';
    position: absolute;
    transition: all 0.25s linear;
    width: 0.3em;
}

.list-item.mini:before {
    height: 2.5em;
    background: var(--accent-color);
    top: 0.15em;
    left: -0.3em;
    transform: skewY(-45deg);
}

code {
    background-color: #1f1e1e;
    color: greenyellow;
    padding: 3px;
}

.blinker_badge {
    white-space: nowrap;
    margin-left: 5px;
    padding: 1px 2px;
    border-radius: 50%;
    background-color: red;
    color: whitesmoke;
    font-size: 0.5rem;
    font-weight: bold;
}

.blinker_item_wrapper {
    margin: 0 0.5rem;
    cursor: pointer;
}

.blinker_input {
    border-radius: 0 !important;
    padding: 2px 5px !important;
    font-size: 0.7rem;
    margin-top: 5px !important;
}

.blinkers_level_2,
.blinkers_level_1 {
    padding: 3px 0;
}

.top_bar_icon {
    border-bottom: 1px solid transparent !important;
}

.top_bar_icon:hover {
    border-bottom: 1px solid var(--accent-color) !important;
}

.xs_text {
    font-size: var(--xs-text);
}

.user_action_item {
    margin: 0;
    padding: 5px 0;
    color: var(--secondary_text);
    border-left: 3px solid transparent;
}

.user_action_item:hover {
    border-left: 3px solid var(--accent-color);
}

/*GAUGE CHART DISPLAY NUMBER*/
.relative {
    position: relative;
}

.absolute-center {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.error_msg {
    background: #ff0033 !important;
    background: linear-gradient(-250deg, #ff0033 10%, indianred 100%) !important;
    color: #e0cfa1 !important;
}

/*CHART CONTROLS*/
.chart_date {
    cursor: pointer;
    padding: 2px 8px;
    margin: 0 3px 3px 3px;
    border-radius: 12px;
    background: var(--secondary_bg) !important;
    color: var(--accent-color);
    font-size: var(--xs-text) !important;
}

.chart_date:hover,
.chart_date.active {
    background: var(--primary_bg) !important;
}

.date_select:hover {
    background: var(--secondary_bg) !important;
    background: linear-gradient(250deg, var(--primary_bg) 10%, var(--secondary_bg) 100%) !important;
}
.chart_type_drop_down_item {
    color: var(--secondary_text) !important;
    padding: 0 5px !important;
    margin: 0 !important;
    font-size: var(--xs-text) !important;
    cursor: pointer;
}

.chart_type_drop_down_item:hover {
    color: var(--primary_text) !important;
    background-color: var(--secondary_bg_60) !important;
}
.chart_type_drop_down_item.select:hover {
    color: var(--primary_text) !important;
    background-color: transparent !important;
}
.backdrop_filter_dark_dates {
    backdrop-filter: blur(5px);
    background-color: var(--dark_bg_40) !important;
    -moz-box-shadow: inset 0 0 10px var(--primary_bg) !important;
    -webkit-box-shadow: inset 0 0 10px var(--primary_bg) !important;
    box-shadow: inset 0 0 10px var(--primary_bg) !important;
}
.tooltip-inner {
    background-color: var(--secondary_bg) !important;
    color: var(--primary_text) !important;
    font-size: var(--xs-text) !important;
}
