.password-wrapper {
    display: inline-flex;
}

.login-password-icon {
    border-bottom: 1px solid #cccccc !important;
    padding-bottom: 6px;
    color: whitesmoke;
    margin-bottom: 3px;
    margin-top: 5px;
    margin-right: 5px;
}

.password-input {
    width: 425px;
}
