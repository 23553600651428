.multiple-select {
    width: 140px;
    border: 1px solid #ccc;
    background-color: white;
    min-height: 22px;
}

.options {
    background-color: #c9c7c7;
    z-index: 2;
}

.option {
    cursor: pointer;
    padding: 3px;
    font-size: 14px;
    border-radius: 5px;
    color: black;
    margin: 0 3px;
}

.option:hover {
    background-color: rgba(0, 123, 255, 0.98);
    color: #fafafa;
    font-weight: bold;
}

.selected {
    background-color: #007bff;
    color: white;
}

.selected-option {
    font-size: 10px;
    background-color: #c9c7c7;
    color: black;
    padding: 0 3px;
    margin-bottom: 3px;
    border-radius: 3px;
}

.num-of-selected {
    font-size: 10px;
    margin-bottom: 10px;
    color: #516186;
    padding-left: 3px;
}

.caret-icon {
    color: black;
    padding-top: 5px;
    padding-right: 2px;
    font-size: 12px;
}

.selected-icon {
    font-size: 12px;
    font-weight: bolder;
}

.popover-multiselect {
    min-width: 150px;
}

.popover-multiselect::-webkit-scrollbar {
    width: 2px !important;
}
