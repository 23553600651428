.password-icon {
    float: right;
    border-left: 1px solid #cccccc !important;
    padding-left: 10px;
    padding-bottom: 2px;
    color: whitesmoke;
}
.password-field {
    width: 180px;
}
