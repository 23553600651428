@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap&Poppins:ital,wght@0,100;0,300;0,400;1,500;1,900&display=swap');
@import 'resources/static/css/animations.css';
@import 'resources/static/css/intratraderRoot.css';
@import url('https://fonts.googleapis.com/css2?family=Shantell+Sans:wght@300&family=Sofia+Sans:wght@1&display=swap');
@import url('https://fonts.googleapis.com/css?family=Sofia+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:wght@100;400&display=swap');
@import url('https://fonts.cdnfonts.com/css/google-sans');
@import url('https://fonts.cdnfonts.com/css/Manrope');
@import url('https://fonts.cdnfonts.com/css/Inter');

:root {
    /*--main-background-color: #010102;*/
    /*--medium-dark-grey: #353840;*/
    /*--light-grey: #2e3137;*/
    --hover: #0c2e68;
    --widget-blue: #0a1e4d;
    --widget-blue-light: #2d3765;
    /*--main-background-color: #282c34;*/
    --main-background-color: #040421;

    --panel-background-color: #07073f;
    --panel-border: 1px solid #dee2e6;
    --panel-radius: 0.25rem;
    --panel-box-shadow: 0 1px 5px 1px rgba(242, 244, 248, 0.25), 0 1px 1px 1px rgba(12, 17, 247, 0.15);

    --modal-background-color: #07073f;
    --modal-border: 1px solid #dee2e6;
    --modal-radius: 0.25rem;
    --modal-box-shadow: 0 1px 5px 1px rgba(242, 244, 248, 0.25), 0 1px 1px 1px rgba(12, 17, 247, 0.15);

    --medium-dark-grey: #353840;
    --light-grey: #2e3137;
    --glossy-black: #202024;
    --ls-green: #6bba20;
    --action-icon: #a89463;
    --green: #80a6e4;
    --purple: #922e92;
    --blue: #4466ff;
    --blue-secondary: #233380;
    --blue-secondary-light: #0c5adb;
    --blue-light: rgba(1, 101, 254, 0.35);
    --muted: #9aa9b2;
    --light-blue: #62b5d1;
    --yellow: #ffc300;
    --yellow-muted: rgba(241, 221, 158, 0.7);
    --brigt-yellow: yellow;
    --yellow-50: rgba(255, 195, 0, 0.75);
    --red: #eb2619;
    --light-red: #d16a61;
    --white: whitesmoke;
    --white-muted: #cccccf;
    --xs-small: 0.75rem;
    --incidents-text-color: #949499;
    --dashed-border: 1px dashed whitesmoke;
    --dashed-border-inactive: 1px dashed var(--red);
    --dashed-border-active: 1px dashed var(--green);
    --dashed-border-white: 1px dashed var(--white);

    --card-radius: 20px;
    --card-padding: 8px;
    --card-margin: 6px;
    --card-min-height: 200px;
    --light-card-background: #2d3765;
    --light-card-text: #9aa9b2;
    --medium-card-background: #4c697a;
    --medium-card-text: #0a1e4d;
    --dark-card-background: #0a1e4d;
    --dark-card-text: #9aa9b2;

    --modal-width: 500px;
    --modal-height: 280px;
    --modal-margin-top: 28px;

    --negative: red;
    --positive: green;
    --neutral: white;
    --column-color: white;
    --header-color: white;
    --panel-bgcolor: #4c697a;

    --box-shadow: 0 1px 5px 1px rgba(242, 244, 248, 0.25), 0 1px 1px 1px var(--bs-gray-900);

    --accent-color-muted: red;

    --scrollbar-track: #202024;
    --scrollbar-thumb-background: rgba(40, 44, 52, 0.7);
    --scrollbar-thumb-border: whitesmoke;
    --scrollbar-hover: #040421;
    --scrollbar-width: 15px;
    --scrollbar-height: 10px;

    --offcanvas-backdrop-height: 700px;
    --offcanvas-backdrop-top: 30px;

    --gusl-offcanvas-width: 390px;
    --internal-height: 800px;
    --internal-width: 800px;
    --gusl-footer-height: 50px;

    --gusl-safe-area-bottom: env(safe-area-inset-bottom);

    --gusl-safe-area-top: 0;
    --gusl-mobile-footer-bar: 50px;
    --table-highlight-background-color: #000;
}

@media only screen and (max-width: 1024px) {
    body {
        width: 100vw;
        overflow: hidden;
        position: fixed;
        height: 100%;
    }
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

p.justify_inter_word {
    text-align: justify;
    text-justify: inter-word;
}

.relative {
    position: relative;
}

.absolute-center {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.relative_bubbles {
    position: relative;
}

.absolute-center_bubbles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bubbles {
    z-index: 99999 !important;
}

.xxs-small {
    font-size: 0.6rem;
}

.widget_heading {
    font-size: 16px !important;
    color: var(--muted) !important;
}

button.btn-close {
    /*background-color: var(--muted)*/
}

.ls-green {
    color: var(--ls-green) !important;
}

.dark-orange {
    color: var(--yellow-50) !important;
}

/*
.dropdown-toggle::after {
    display: none !important;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
*/

.top_nav_item {
    cursor: pointer !important;
}

.top_nav_item:hover {
    background-color: rgba(128, 166, 228, 0.2) !important;
}

.top_nav_item.active {
    border-top: 1px solid var(--green) !important;
    border-left: 1px solid var(--green) !important;
    border-right: 1px solid var(--green) !important;
    border-bottom: none !important;
    background-color: rgba(128, 166, 228, 0.2) !important;
    /*border-top-left-radius: 10px!important;*/
    /*border-top-right-radius: 10px!important;*/
}

.muted {
    color: var(--muted) !important;
}

textarea {
    background-color: #cfd3dc !important;
    color: var(--widget-blue-light) !important;
}

.widget_blue {
    background-color: var(--widget-blue) !important;
}

/*GLOSSY EFFECT*/
.light_red {
    color: var(--light-red) !important;
}

.yellow_muted {
    color: var(--yellow-muted) !important;
    font-weight: 300 !important;
}

.darkturquoise {
    color: darkturquoise;
}

.text-black {
    color: var(--glossy-black) !important;
}

a,
a:hover {
    text-decoration: none !important;
}

.landing_page {
    background-color: var(--widget-blue);
    height: var(--internal-height);
    width: 100vw;
}

.landing_bgr {
    min-height: 670px;
    width: 150% !important;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--glossy-black);
    /*background-image: linear-gradient(*/
    /*        #202024 ,*/
    /*        rgba(68, 102, 255, 0.25) 35%,*/
    /*        rgba(68, 102, 255, 0.35) 65%,*/
    /*        #202024 ),*/
    /*url("./resources/images/login.png");*/
}

/*
#myDIV {
  width: 300px;
  height: 200px;
  background: red;
  animation: mymove 5s infinite;
}

@keyframes mymove {
  from {background-color: red;}
  to {background-color: blue;}
}
*/

/*.text-light, table *, th.incidents_table {*/
/*    color: var(--muted) !important;*/
/*}*/

.dark_bg {
    background-color: var(--muted) !important;
}

.blue_bg {
    background-color: var(--blue-light) !important;
}

.yellow_bg {
    background-color: var(--yellow) !important;
}

.red_bg {
    background-color: red;
}

.bright_yellow_bg {
    background-color: var(--brigt-yellow) !important;
    filter: contrast(150%) !important;
}

.green_bg {
    background-color: var(--green) !important;
}

.medium_dark_bg {
    background-color: var(--medium-dark-grey) !important;
}

.light_grey_bg {
    background-color: var(--light-grey);
}

.border_bottom_grey {
    border-bottom: 0.5px solid var(--light-grey);
}

.border_bottom_muted {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid var(--muted) !important;
}

.border_bottom_blue {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid var(--green) !important;
}

.border-muted {
    border: 1px solid var(--muted);
}

.backdrop_filter {
    /*backdrop-filter: blur(10px);*/
    /*background-color: rgba(40, 44, 52, 0.75);*/
    background-color: var(--panel-bgcolor);
    border-radius: 10px;
    margin-bottom: 50px;
}

.light_blue {
    color: var(--light-blue) !important;
}

/* commented out reports*/
/*tr {*/
/*    border-bottom: 1px solid var(--medium-dark-grey) !important;*/
/*}*/

table,
tr,
th,
td {
    /*color: var(--muted) !important;*/
    border-collapse: collapse;
}

.table_td {
    color: var(--light-grey) !important;
}

option {
    color: var(--main-background-color) !important;
}

.chart-placeholder {
    opacity: 0.9;
    height: 150px !important;
    width: 150px !important;
    border-radius: 100% !important;
    background: var(--blue) !important;
    background: radial-gradient(circle, var(--blue) 18%, var(--red) 33%, var(--yellow) 42%) !important;
}

.Active {
    background-color: var(--green) !important;
    color: var(--white);
}

select {
    /*
    background-color: var(--medium-dark-grey) !important;
    */
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

select.Active,
select.Inactive,
select.Standby {
    background-color: transparent !important;
    margin-top: 7px !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    font-size: var(--xs-small) !important;
    text-align: center !important;
    padding: 2px !important;
}

select.Active:hover,
select.Inactive:hover,
select.Standby:hover {
    background-color: var(--widget-blue-light) !important;
}

select.Active {
    border-bottom: 2px solid var(--green) !important;
}

.block-pagination-active {
    border-bottom: 5px dashed var(--medium-dark-grey) !important;
}

.links {
    overflow-wrap: break-word;
    hyphens: manual;
}

/*changing color of caret to match*/
select.form_select {
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%239aa9b2%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e') !important;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 15px;
}

.Inactive {
    background-color: var(--red) !important;
    color: var(--white);
}

select.Inactive {
    border-bottom: 2px solid var(--red) !important;
}

.Standby {
    background-color: var(--yellow) !important;
}

select.Standby {
    border-bottom: 2px solid var(--yellow) !important;
}

.toolbarIcon:hover {
    color: var(--white) !important;
}

.nav-link {
    margin: 0 2px 0 2px !important;
}

.dashed-border-inactive {
    border: 0 !important;
    border-bottom: var(--dashed-border-inactive) !important;
}

.dashed-border-white {
    border: 0 !important;
    border-bottom: var(--dashed-border-white) !important;
}

.dashed-border-active,
.add-device:hover {
    border: 0 !important;
    border-bottom: var(--dashed-border-active) !important;
}

.edit-device:hover {
    color: var(--blue) !important;
}

.delete-device:hover {
    color: var(--red) !important;
}

.not_set {
    background-color: black !important;
}

.x-small {
    font-size: 0.9rem !important;
}

.xs-small {
    font-size: var(--xs-small) !important;
}

.green,
td.green {
    color: var(--green) !important;
}

.blue,
.Active-text {
    color: var(--blue) !important;
}

.blue_secondary_light {
    color: var(--blue-secondary-light);
}

.red,
.Inactive-text {
    color: var(--red) !important;
}

.yellow,
.Standby-text,
.invalid,
.error-message {
    color: var(--yellow) !important;
}

.blue-light {
    color: var(--blue-light);
}

.active-category {
    /*border-start-end-radius: 15px;*/
    background-color: var(--white);
    color: var(--main-background-color);
}

.active.nav-link {
    font-weight: bold !important;
}

button.nav-link {
    color: var(--white) !important;
}

button.nav-link.active {
    background-color: transparent !important;
    border: 0 !important;
    border-bottom: 2px solid var(--white) !important;
}

button.nav-link:hover {
    border: 0 !important;
    border-bottom: 2px solid var(--white) !important;
}

.dashed-border-btn {
    border-bottom: 1px dashed var(--white);
}

.device-status:hover {
    border-bottom: 1px dashed var(--white);
}

.logSourceToggle {
    color: var(--green) !important;
}

.logSourceToggle:hover {
    background-color: var(--widget-blue-light) !important;
}

.page-link {
    /*border: 1px solid var(--muted) !important;*/
    border: none !important;
    background-color: var(--main-background-color) !important;
    margin-right: 1px !important;
    margin-left: 1px !important;
    color: var(--muted) !important;
}

.page-link:hover {
    color: var(--yellow) !important;
}

.modal_btn.cancel {
    border: none !important;
    border-bottom: 1px solid var(--muted) !important;
    border-radius: 0 !important;
    font-weight: bolder !important;
    color: var(--red) !important;
    background-color: transparent !important;
}

.modal_btn.confirm {
    border: none !important;
    border-bottom: 1px solid var(--muted) !important;
    border-radius: 0 !important;
    font-weight: bolder !important;
    color: var(--green) !important;
    background-color: transparent !important;
}

.modal_btn:hover {
    background-color: var(--main-background-color) !important;
}

.modal-body {
    background-color: var(--modal-background-color) !important;
}

/*.modal-content {*/
/*    background-color: var(--main-background-color) !important;*/
/*    border: 1px solid var(--blue) !important;*/
/*    color: var(--white) !important;*/
/*    opacity: 95% !important;*/
/*}*/

/*CUSTOM SCROLLBAR*/
/* width */
::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-height);
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-background);
    border: 1px solid var(--scrollbar-thumb-border);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-hover);
}

/*Self assessment*/
.questions-block {
    /*border: 1px solid red;*/
}

.question_with_answer_select:hover {
    background-color: rgba(32, 32, 36, 0.4) !important;
}

.sa-btn:hover {
    border-start-end-radius: 29px;
    background-color: var(--white) !important;
    color: var(--main-background-color) !important;
}

.category:not(.side_nav) {
    border-left: 3px solid var(--glossy-black) !important;
}

.category:not(.side_nav):hover {
    border-left: 3px solid var(--green) !important;
}

/*
.sn_menu_item, .dropdown-item {
    border-left: 3px solid var(--main-background-color) !important;
    background-color: transparent !important;
}

 */

.block_categories {
    border-left: 3px solid transparent !important;
}

.block_categories:hover {
    border-left: 3px solid var(--green) !important;
}

.sn_menu_item:hover,
.sn_menu_item.active,
.block_categories.active,
.incidents.side_nav.category:hover {
    border-left: 3px solid var(--blue) !important;
    cursor: pointer;
}

.sn_menu_item.main {
    border-left: 3px solid var(--main-background-color) !important;
}

.sn_menu_item.main:hover,
.sn_menu_item.main.active {
    border-left: 3px solid var(--green) !important;
}

.subcategory {
    border-left: 10px solid var(--light-grey);
}

.block-active {
    border-start-end-radius: 29px;
    background-color: var(--blue-light) !important;
    color: var(--white) !important;
}

.progress_bar_block_vertical {
    width: 10px !important;
}

.progress_bar_category_vertical {
    width: 8px !important;
}

.progress_bar_subcategory_vertical {
    width: 6px !important;
}

.progress_bar_question_vertical {
    width: 4px !important;
}

.progress_bar_block_horizontal {
    width: auto !important;
    height: 4px !important;
}

.target_score {
    color: var(--blue-secondary-light) !important;
}

.client_score {
    color: var(--green) !important;
}

.radio_button {
    width: 15px !important;
    height: 15px !important;
    border-radius: 100% !important;
    background-color: var(--white);
}

.radio_button.selected {
    background: rgb(2, 0, 36);
    background: radial-gradient(
        circle,
        var(--glossy-black) 0%,
        var(--glossy-black) 45%,
        rgba(255, 255, 255, 1) 45%,
        rgba(255, 255, 255, 1) 100%
    );
    border: 1px solid var(--glossy-black) !important;
}

/*.form_input {*/
/*    border-left: 0 !important;*/
/*    border-right: 0 !important;*/
/*    border-top: 0 !important;*/
/*    border-bottom: 1px solid var(--white) !important;*/
/*    border-radius: 0 !important;*/

/*}*/

.answer_label.active {
    /*border-bottom: 2px solid var(--blue);*/
    color: var(--white) !important;
}

/*MODULES FOR SH0PPING*/
.module.wrapper:hover {
    cursor: pointer;
    /*background-color: var(--main-background-color);*/
}

.module.title {
    border-left: 6px solid var(--blue);
}

.module.option {
    border-left: 3px solid var(--blue);
}

.module.option:hover,
.module.option.active {
    /*background-color: var(--main-background-color);*/
}

.search_box {
    background-color: var(--bs-gray-800) !important;
}

.search_box_drop {
    background-color: var(--bs-gray-800) !important;
    border-right: 1px solid var(--bs-gray-700) !important;
}

/*.drag_icon {*/
/*    color: var(--bs-gray-700);*/
/*}*/

/*.drag_icon:hover, .drag_icon:active {*/
/*    cursor: move !important;*/
/*}*/

.f_wrapper {
    cursor: move !important;
}

.module.option.active:hover {
    background-color: var(--medium-dark-grey);
}

.module.link:hover {
    background-color: var(--white);
    color: var(--main-background-color);
}

.module.select:hover {
    background-color: var(--main-background-color);
}

.discount {
    padding: 2px 3px 2px 3px;
    border-radius: 3px;
}

.discount_2 {
    background-color: var(--yellow-50) !important;
}

.discount_3 {
    background-color: var(--red) !important;
}

/*INCIDENTS*/
.incidents.side_nav.category,
.incidents.side_nav.block,
.incidents.overview {
    color: var(--muted) !important;
    border-left: 5px solid transparent !important;
}

.incidents.side_nav.block {
    font-size: 15px !important;
}

.incidents.side_nav.category:hover,
.incidents.side_nav.category.active,
.incidents.overview.active,
.incidents.overview:hover {
    background-color: var(--light-grey) !important;
    border-left: 5px solid var(--blue) !important;
}

.inside_border_right_bottom {
    border-bottom-right-radius: var(--card-radius) !important;
}

.inside_border_left_bottom {
    border-bottom-left-radius: var(--card-radius) !important;
}

.inside_border_right_top {
    border-top-right-radius: var(--card-radius) !important;
}

.inside_border_left_top {
    border-top-left-radius: var(--card-radius) !important;
}

.inside_border_all,
textarea.chatBox {
    border-radius: var(--card-radius) !important;
}

/*box-shadow: none|h-offset v-offset blur spread color |inset|initial|inherit;*/
.box_shadow {
    box-shadow: var(--box-shadow);
    /*0 1px 1px 1px rgba(21, 250, 0, 0.85),*/
    /*0 1px 1px 1px rgba(250, 192, 0, 0.85);*/
}

.inside_border_right_top.glossy:after,
.inside_border_all.glossy:after {
    border-top-right-radius: var(--card-radius);
}

.incidents.body.first {
    position: relative;
}

/*.incidents.body.first.child {*/

/*    position: absolute;*/
/*    width: 60px !important;*/
/*    height: 60px !important;*/
/*    bottom: -30px !important;*/
/*    right: -30px !important;*/
/*    border: 1px solid red;*/
/*    background-image: url("./resources/images/eye2.svg");*/
/*}*/

.incidents.side_nav.last {
    max-height: 300px;
    overflow: scroll;
}

tr.incidents:hover {
    background-color: var(--main-background-color) !important;
}

tr.incidents:hover .edit_icon .fas.fa-tools {
    color: var(--green) !important;
}

.hit_box {
    color: var(--incidents-text-color) !important;
}

.info_box.hit_box:nth-child(1) {
    border-left: 5px solid var(--purple);
}

.info_box.hit_box:nth-child(2) {
    border-left: 5px solid var(--yellow);
}

.info_box.hit_box:nth-child(3) {
    border-left: 5px solid var(--green);
}

.info_box.hit_box:nth-child(4) {
    border-left: 5px solid var(--blue);
}

.info_box.hit_box:nth-child(5) {
    border-left: 5px solid var(--red);
}

.info_box.hit_box:nth-child(6) {
    border-left: 5px solid var(--white);
}

textarea.chatBox {
    background-color: rgba(245, 245, 245, 0.85) !important;
    border: 0;
    min-height: 180px !important;
    color: var(--main-background-color) !important;
}

.chatBoxLabel {
    color: var(--main-background-color) !important;
}

.chatBoxBtn {
    padding: 3px 8px !important;
    background-color: var(--main-background-color) !important;
    color: var(--incidents-text-color) !important;
}

.btn.action1 {
    border: 3px solid transparent !important;
    border-bottom: 3px solid var(--purple) !important;
    color: var(--incidents-text-color) !important;
}

.btn.action1:hover {
    border: 3px solid var(--purple) !important;
}

.btn.action2 {
    border: 3px solid transparent !important;
    border-bottom: 3px solid var(--green) !important;
    color: var(--incidents-text-color) !important;
}

.btn.action2:hover {
    border: 3px solid var(--green) !important;
}

.btn.action3 {
    border: 3px solid transparent !important;
    border-bottom: 3px solid var(--blue) !important;
    color: var(--incidents-text-color) !important;
}

.btn.action3:hover {
    border: 3px solid var(--blue) !important;
}

.btn.action4 {
    border: 3px solid transparent !important;
    border-bottom: 3px solid var(--white) !important;
    color: var(--incidents-text-color) !important;
}

.btn.action4:hover {
    border: 3px solid var(--white) !important;
}

.rounded_action_btn {
    border-radius: 30px !important;
}

.rounded_action_btn:hover {
    background-color: var(--white);
    color: var(--main-background-color) !important;
}

.actionChart {
    /*border: 1px solid transparent !important;*/
    /*border-bottom: 1px solid var(--white) !important;*/
    color: var(--incidents-text-color) !important;
}

/*.actionChart:hover {*/

/*    border-bottom: 1px solid var(--green) !important;*/

/*}*/

.actionChart.active {
    /*font-weight: 1000;*/
    border-bottom: 1px solid var(--green) !important;
}

.chart_select {
    color: var(--incidents-text-color);
    cursor: pointer;
}

.chart_select:hover,
.chart_select.active {
    color: var(--white);
}

.btn.actionMuted {
    border: 3px solid transparent !important;
    border-bottom: 3px solid var(--light-grey) !important;
    color: var(--light-grey) !important;
}

.modal-content {
    background-color: var(--modal-background-color) !important;
    border: var(--modal-border) !important;
    border-radius: var(--modal-radius) !important;
    box-shadow: var(--modal-box-shadow) !important;
    opacity: 100% !important;
    margin-top: var(--modal-margin-top) !important;
}

.selectedHitModal {
    background-color: var(--light-grey) !important;
    color: var(--incidents-text-color) !important;
    border: none !important;
}

.hitDoTo {
    border-left: 5px solid var(--white) !important;
}

.hitToDo.done {
    border-left: 5px solid var(--green) !important;
}

/*VISIONX CHART     */
.visionXChart {
    border-left: 1px solid var(--incidents-text-color) !important;
    border-bottom: 1px solid var(--incidents-text-color) !important;
    width: 90% !important;
}

.visionXChartBar {
    cursor: pointer;
}

.visionXChartBar:hover {
    opacity: 60%;
}

.visionXChartLabel {
    font-size: 0.6rem;
    transform: skew(-0.06turn, 18deg);
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.divider_wrapper_transparent {
    margin-top: 5px;
    height: 10px;
    width: 100%;
    background-color: transparent !important;
}

.gradient_line span:before {
    content: '';
    position: absolute;
    top: 100%;
    width: 40%;
    left: 0;
    height: 3px;
    border-radius: 2px;
    background: linear-gradient(
        130deg,
        var(--widget-blue),
        whitesmoke 50.07%,
        var(--main-background-color) 99.5%,
        var(--main-background-color) 100%
    );
}

.gradient_line_mobile span:before {
    content: '';
    position: absolute;
    top: 5%;
    width: 30%;
    left: 0;
    height: 3px;
    border-radius: 2px;
    background: linear-gradient(
        130deg,
        var(--widget-blue),
        whitesmoke 50.07%,
        var(--main-background-color) 99.5%,
        var(--main-background-color) 100%
    );
}

.divider_wrapper {
    margin-top: 0;
    height: 5px;
    width: 100%;
    background-color: transparent;
}

.divider:before {
    content: '';
    position: absolute;
    top: 7%;
    width: 100%;
    left: 0;
    height: 2px;
    background: linear-gradient(130deg, #0d6efd, whitesmoke 65.07%, var(--widget-blue) 99.5%, var(--widget-blue) 100%);
}

.divider_incident:before {
    content: '';
    position: absolute;
    top: 6%;
    width: 40%;
    left: 0;
    height: 2px;
    background: linear-gradient(130deg, #0d6efd, whitesmoke 65.07%, #2b2b2b 80.05%, #2b2b2b 100%);
}

.sorter:hover {
    color: var(--green);
}

.bar_holder {
    background-color: #202024 !important;
    border: 0.1px solid rgba(0, 206, 209, 0.2);
}

/*DATE-RANGE SELECT ON INCIDENTS TABLE*/
.bar_holder:hover {
    background-color: rgba(0, 206, 209, 0.15) !important;
    cursor: cell;
}

.day_bar:hover {
    cursor: cell;
}

.bar_holder.selected {
    background-color: rgba(0, 206, 209, 0.3) !important;
    cursor: cell;
}

.day_bar {
    background-color: darkturquoise;
}

/*OVERWRITING POPOVERS CLASSES*/
/*.popover {*/
/*    background-color: var(--main-background-color) !important;*/
/*    border: 2px solid var(--white-muted) !important;*/
/*    width: 100% !important;*/
/*}*/

/*.popover-header {*/
/*    background-color: var(--glossy-black) !important;*/
/*    padding: 5px 0 0 0 !important;*/

/*    margin: 0 !important;*/
/*    margin-left: 10px !important;*/
/*}*/

/*.popover-body {*/
/*    margin: 0 !important;*/
/*    padding: 2px !important;*/

/*}*/

.filter {
    color: var(--white-muted) !important;

    border-left: 2px solid var(--glossy-black);
}

/*.filter:hover{*/
/*    border-left: 2px dashed var(--green);*/
/*}*/
.table_sorter:hover,
.fa:hover,
.far.fa-plus-square:hover,
.fa-sliders-h:hover {
    color: var(--green) !important;
}

.far.fa-minus-square:hover {
    color: var(--yellow) !important;
}

.detailed_filters {
    border-radius: 10%;
    font-size: 0.7rem !important;
}

.detailed_filters:hover {
    color: var(--green) !important;
    border: 1px solid var(--green) !important;
}

.far {
    font-weight: 100;
}

.reset_filter {
    color: rgba(255, 99, 132, 0.5);
    font-weight: 100;
}

.reset_filter:hover {
    color: rgba(255, 99, 132, 0.75);
}

.filter_item {
    border: 1px solid #a6adc6;
    border-radius: 5px;
    font-size: 0.7rem;
    color: var(--light-grey);
    padding: 2px 4px;
    cursor: pointer;
    margin-right: 5px;
}

.sort {
    border: none;
    margin-right: 2px;
}

.filter_item:not(.sort):hover {
    border: 1px solid var(--yellow);
}

/*OVERWRITING DROPDOWN MENU CLASS FOR DATES DROPDOWN*/
/* if uncomment then see navbar/styled.ts
.dropdown-menu {
    background-color: var(--main-background-color) !important;
}

.dropdown-item:hover {
    background-color: initial !important;
    border-left: 3px solid var(--green) !important;
}
*/

.column,
.filter,
.light_bottom_border {
    border-bottom: 1px solid #474747;
}

.searchable_item {
    border-bottom: 1px solid var(--accent-color-muted-60);
}

.searchable_item:hover {
    background-color: var(--accent-color-muted-60);
}

/*.column{*/
/*    background-color: #363636;*/
/*}*/
.column:hover,
.filter:hover {
    background-color: var(--bs-gray-800);
}

.column.active {
    border: 1px dashed var(--bs-gray-400);
    /* box-shadow: var(--box-shadow);*/
}

.drag_cursor {
    cursor: move;
}

/*SINGLE INCIDENT*/
.reply_btn {
    cursor: pointer;
    background-color: var(--widget-blue-light);
    color: var(--muted);
    border: 1px solid var(--muted);
    padding: 5px 35px;
    border-radius: 20px;
}

.reply_btn:hover {
    background-color: var(--widget-blue);
}

.incident_tab {
    text-align: center;
    /*border-bottom: 1px solid var(--green);*/
    /*border-radius: var(--card-radius);*/
    /*margin: 0 5px;*/
    /*padding: 3px  0 0 0;*/
    color: whitesmoke;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

.incident_tab:hover {
    /*background-color: var(--widget-blue-light) !important;*/
    border-top: 1px solid var(--muted);
    color: var(--muted) !important;
    /*border-bottom: 2px solid var(--green);*/
    /*border-bottom: none;*/
    /*border-top:1px solid var(--green);*/
    /*border-left: 1px solid var(--green);*/
    /*border-right: 1px solid var(--green);;*/
}

.incident_tab.active {
    /*background-color: var(--muted) !important;*/
    color: var(--dark-card-text) !important;
    border-top: 2px solid var(--green);
    /*border-bottom: none;*/
    /*border-top:1px solid var(--green);*/
    /*border-left: 1px solid var(--green);*/
    /*border-right: 1px solid var(--green);*/
}

.incident.box {
    border: 1px solid var(--medium-dark-grey);
    border-radius: 5px;
}

.incident_right_nav {
    min-height: 75vh !important;
}

.summary_field {
    border-left: 3px solid transparent;
}

.summary_field.active,
.summary_field:hover {
    border-left: 3px solid var(--green);
}

.rte {
    margin: 0 5px;
    cursor: pointer;
    color: var(--dark-card-text);
}

.rte:hover {
    color: var(--green) !important;
}

/*OVERVIEW DASHBOARD*/

.threatCategoryTblCell {
    padding-left: 5px;
    padding-right: 5px;
}

.threatCategoryTblCell.value {
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid var(--red);
}

.RSA_acronym,
.EA_acronym,
.AE_acronym,
.AD_acronym,
.PEA_acronym,
.CAC_acronym {
    font-weight: bold;
    width: 35px !important;
    height: 35px !important;
    border-radius: 100%;
    background-color: var(--widget-blue);
}

.P1_bgr,
.P2_bgr,
.P3_bgr,
.P4_bgr,
.P_zero_value {
    background-color: var(--widget-blue);
    border-radius: 10px;
}

/*.P1_bgr:hover, .P2_bgr:hover, .P3_bgr:hover, .P4_bgr:hover{*/
/*    box-shadow: 0 1px 5px 1px rgba(242, 244, 248, 0.25),*/
/*    0 1px 1px 1px rgba(12, 17, 247, 0.15);*/
/*}*/
.P1_bgr {
    border: 2px solid rgba(255, 99, 132, 0.7);
}

.P2_bgr {
    border: 2px solid rgba(255, 206, 86, 0.7);
}

.P3_bgr {
    border: 2px solid rgba(75, 192, 192, 0.7);
}

.P4_bgr {
    border: 2px solid rgba(54, 162, 235, 0.7);
}

.P_zero_value {
    border: 2px solid rgba(119, 122, 123, 0.33);
    color: rgba(119, 122, 123, 0.33) !important;
}

/*
   'P1':'rgba(255, 99, 132, 0.7)',
        'P2':'rgba(255, 206, 86, 0.7)',
        'P3':'rgba(75, 192, 192, 0.7)',
        'P4':'rgba(54, 162, 235, 0.7)',*/
.placeholder-glow,
.placeholder {
    cursor: initial !important;
}

.white_space_break_spaces {
    white-space: break-spaces;
}

/*PLAYGROUND*/
.fade.offcanvas-backdrop.show {
    background-color: transparent !important;
}

.offcanvas_wrapper {
    opacity: 90% !important;
}

#no_phish_diagram {
    /*width="327.95" height="125.98"*/
    /*width:327.95px;*/
    /*height:125.98px;*/
    max-width: 300px;
    /*max-height:300px;*/
    min-height: 150px;
}

.dark_card {
    min-height: var(--card-min-height);
    border-radius: var(--card-radius) !important;
    padding: var(--card-padding);
    margin: var(--card-margin);
    background-color: var(--dark-card-background);
}

.dark_card_text {
    color: var(--dark-card-text) !important;
}

.light_card {
    min-height: var(--card-min-height);
    border-radius: var(--card-radius) !important;
    padding: var(--card-padding);
    margin: var(--card-margin);
    background-color: var(--light-card-background);
}

.light_card_text {
    color: var(--light-card-text) !important;
}

/*SOME MEDIUM COLORS....*/
.medium_card {
    min-height: var(--card-min-height);
    border-radius: var(--card-radius) !important;
    padding: var(--card-padding);
    margin: var(--card-margin);
    background-color: var(--medium-card-background);
}

.medium_card_text {
    color: var(--medium-card-text) !important;
}

.summary_item_widget {
    border-bottom-right-radius: var(--card-radius) !important;
    border-top-right-radius: var(--card-radius) !important;
    padding: 5px 10px;
    margin-bottom: 5px;
    /*border:1px solid var(--muted);*/
    border-left: 5px solid var(--dark-card-text);
    background-color: var(--widget-blue-light);
}

.summary_item_widget:hover,
.summary_item_widget.active {
    border-left: 5px solid var(--purple);
}

/*ADMIN PAGE testing only*/
@media only screen and (min-width: 768px) {
    .login_box {
        position: fixed;
        bottom: 10px;
    }
}

.button {
    padding: 3px 7px;
    margin: 0 2px;
    border-radius: 10px;
    /*color: var(--widget-blue-light);*/
}

.button.default {
    border: 1px solid var(--green);
    /*color: var(--green);*/
}

.button.success {
    border: 1px solid var(--ls-green);
    color: var(--ls-green);
}

.button.danger {
    border: 1px solid var(--yellow-muted);
    color: var(--yellow-muted);
}

.invalid_input {
    border: 3px solid var(--light-red);
}

input.calendar {
    color-scheme: dark !important;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-calendar-picker-indicator {
    /*position: relative;*/
    /*left:10px;*/
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="cccccf" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>') !important;
}

input[type='checkbox' i][disabled] {
    background: gray;
    border: 1px solid #d4d4d5;
    color: #25256b !important;
}

input[type='password'],
input[type='text'],
input[type='number'],
textarea {
    -webkit-appearance: none;
    border: none;
    background: inherit !important;
    width: 90%;
}

.roles_menu {
    width: 100% !important;
    background-color: whitesmoke !important;
    box-shadow: var(--box-shadow);
    padding-bottom: 20px;
}

.roles_menu .dropdown-item {
    color: var(--widget-blue) !important;
    font-size: 16px !important;
    border-left: 3px solid whitesmoke !important;
}

.roles_menu .dropdown-item:hover {
    border-left: 3px solid whitesmoke !important;
}

.narrow_dropdown {
    /*background-color: var(--widget-blue) !important;*/
    max-height: 200px;
    width: 30px !important;
    overflow: scroll;
    overflow-x: hidden;
}

.narrow_dropdown > .dropdown-item,
.narrow_dropdown.dropdown-menu {
    min-width: 2px !important;
    width: 60px !important;
}

/*.narrow_dropdown > .dropdown-item:hover {*/
/*    !*background-color: var(--accent-color);*!*/
/*    !*color: var(--dark-color) !important;*!*/
/*}*/

.accent_color {
    color: var(--accent-color);
}

.dark_color {
    color: var(--dark-color) !important;
}

.accent_bg {
    background-color: var(--accent-color) !important;
    color: var(--dark-color) !important;
}

.accent_color_muted {
    color: var(--accent-color-muted);
}

.accent_color_muted_60 {
    color: var(--accent-color-muted-60);
}

.narrow_dropdown.dropdown-menu::-webkit-scrollbar {
    width: var(--scrollbar-width);
}

.range_query:hover,
.range_query.active {
    background-color: var(--accent-color) !important;
    color: var(--dark-color) !important;
}

.btn,
select {
    outline: none !important;
    box-shadow: none !important;
}

.badge_set:hover {
    background-color: var(--dark-color);
    color: var(--accent-color);
}

.left_hand_menu_wrapper::-webkit-scrollbar {
    width: var(--scrollbar-width);
}

.gusl-small {
    font-size: 14px;
}

.highlight {
    background-color: var(--table-highlight-background-color);
}

.table {
    margin: 0px;
}

.table > :not(caption) > * > * {
    padding: 5px 5px;
}

.table.watchList {
    margin: 0;
    padding: 0;
}

.modal-wrapper {
    width: var(--modal-width);
    min-width: var(--modal-width);
}

.form-control:not(.lookup):not(.cron) {
    height: fit-content;
}

.sliding-panel-container {
    position: fixed;
    top: 0;
    left: 0;
}

.sliding-panel-container.active {
    position: fixed;
    z-index: 15000;
    background-color: rgba(0, 0, 0, 0.3);
    will-change: transform;
}

.sliding-panel-container.active.click-through {
    pointer-events: none;
    background-color: transparent;
}

.sliding-panel-container.click-through > div {
    pointer-events: none;
}

.panel-content {
    height: 100%;
    width: 100%;
    pointer-events: all;
}

.panel-container-left-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.panel-container-left-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
}

.panel-container-left-exit {
    opacity: 1;
    transform: translateX(0);
}

.panel-container-left-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 500ms, opacity 500ms;
}

.panel-container-right-enter {
    opacity: 0;
    transform: translateX(100%);
}

.panel-container-right-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
}

.panel-container-right-exit {
    opacity: 1;
    transform: translateX(0);
}

.panel-container-right-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 500ms, opacity 500ms;
}

.panel-container-top-enter {
    opacity: 0;
    transform: translateY(-100%);
}

.panel-container-top-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
}

.panel-container-top-exit {
    opacity: 1;
    transform: translateX(0);
}

.panel-container-top-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 500ms, opacity 500ms;
}

.panel-container-bottom-enter {
    opacity: 0;
    transform: translateY(100%);
}

.panel-container-bottom-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
}

.panel-container-bottom-exit {
    opacity: 1;
    transform: translateX(0);
}

.panel-container-bottom-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 500ms, opacity 500ms;
}

.modal-open {
    padding-right: 0px !important;
    width: 100vw !important;
    height: var(--internal-height) !important;
}

.offcanvas-backdrop {
    height: var(--offcanvas-backdrop-height) !important;
    top: var(--offcanvas-backdrop-top) !important;
}

.offcanvas.offcanvas-start {
    border: none;
}

.draggable-dialog-header {
    cursor: move;
    width: 100%;
}

.row-selected {
    background-color: var(--table-highlight-background-color);
}

#gjs {
    border: 3px solid #444;
}

/* Reset some default styling */
.gjs-cv-canvas {
    top: 0;
    width: 100%;
    height: 100%;
}

.grid-item__title:hover {
    cursor: move;
}

.react-grid-item > .react-resizable-handle::after {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
}

/*
.offcanvas.offcanvas-end {
    width: var(--gusl-offcanvas-width) !important;
}
.offcanvas.offcanvas-start {
    width: var(--gusl-offcanvas-width) !important;
}
*/

#lookup_ff_trait {
    .lmc {
        width: 100% !important;
        position: unset;
    }

    #lookup_cc_trait {
        width: 100% !important;
    }

    .form-control {
        border: none;
    }

    #lookup_matches_cont {
        width: 98%;
    }

    .btn-down-arrow {
        background-color: unset;
    }
}

.gjs-trt-trait {
    #trait-form-panel {
        width: 100% !important;
    }
}

.gjs-field-form {
    .col-xl-2 {
        width: 100% !important;
    }
}

#lookup_li_trait {
    font-size: 12px;
}

#lookup_results_tcontent_trait {
    .tbl_content {
        max-height: 400px !important;
        font-size: 12px !important;
    }
}

#lookup_matches_ms_trait,
#lookup_matches_cw {
    position: unset;
    width: 100% !important;
}

#lookup_cc_trait {
    background-color: unset;
}

.resizable {
    /*background: white;*/
    /*width: 100px;*/
    /*height: 100px;*/
    /*position: absolute;*/
    /*top: 100px;*/
    /*left: 100px;*/
}

.resizable .resizers .resizer {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    border: 3px solid #4286f4;
    position: absolute;
}

.resizable .resizers .resizer.top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize; /*resizer cursor*/
}

.resizable .resizers .resizer.top-right {
    right: -5px;
    top: -5px;
    cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-right {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
}

.jupiter {
    height: 100% !important;
}

.jodit_fullsize {
    z-index: 10000;
}

.react-datepicker__input-container {
    width: 200px;
}

.fs-20 {
    font-size: 20px;
}

.fs-30 {
    font-size: 30px;
}

.green-icon {
    color: rgba(41, 160, 132, 1);
    padding: 5px 5px 2px 5px;
    position: relative;
    top: 3px;
}

.modal-wrapper {
    @media (max-width: 992px) {
        --modal-width: calc(100vw - 20px);
        --modal-height: calc(100vh - 30px);
        --modal-margin-top: 10px;
    }
}
